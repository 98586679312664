import React from "react";
import { OMP } from "../ompContext";
import { withRouter } from "react-router-dom";
import DetailBox from "../DetailBox";
import "./index.less";

const Loading = Loader.Loading;
const IconFont = Loader.loadBaseComponent("IconFont");
const NoData = Loader.loadBaseComponent("NoData");
@withRouter
@Decorator.businessProvider("tab")
@OMP
class DetailLabel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dictLabel: [
        ...Dict.map.personnelAttr,
        ...Dict.map.gait,
        ...Dict.map.height,
        ...Dict.map.fatAndThin,
        ...Dict.map.identity,
        ...Dict.map.aidBehavior,
        ...Dict.map.behaviorAttr,
        ...Dict.map.aidBehaviorCode,
        ...Dict.map.appearPlace,
        ...Dict.map.faceMask
      ]
    };
  }

  jump = item => {
    let { tab, location } = this.props;
    const id = Utils.uuid();
    let data = this.state.dictLabel.find(v => v.value == item);
    let searchData = {
      recommends: [{ id: item, description: data.label, type: 0 }]
    };
    window.LM_DB.add("parameter", {
      id,
      searchData
    }).then(() => {
      tab.closeTabForName("objectMapPersonnelList");
      tab.goPage({
        moduleName: "objectMapPersonnelList",
        location,
        data: { id }
      });
    });
  };

  render() {
    let { labelList, title = "人员标签：", onClick, tagsLoading } = this.props;
    const { dictLabel } = this.state;
    return (
      <DetailBox
        title={title}
        className="detail-label"
        renderItem={
          <div className="edit" onClick={() => onClick && onClick()}>
            <IconFont type={"icon-S_Edit_Edit"} theme="outlined" /> 编辑
          </div>
        }
      >
        {!!labelList.length ? (
          labelList.map(item => {
            let tag = {}
            if (dictLabel.length > 0) {
              tag = dictLabel.find(v => v.value == item);
            }
            let timeSpace = ["119051", "118703", "118702"];
            if (item - 120700 > 0 && item - 120700 < 21) {
              timeSpace.push(item);
            }
            return (
              <div className="label-info" onClick={() => this.jump(item)}>
                {timeSpace.includes(tag.value) ? (
                  <div className="label label-palce label-chose">
                    <span className="label-palce-icon">
                      <IconFont type={"icon-S_Bar_Eye"} theme="outlined" />{" "}
                    </span>
                    {tag.label}
                  </div>
                ) : (
                  tag.label
                )}
              </div>
            );
          })
        ) : (
          <p className='no-tag'>暂无标签</p>
        )}
      </DetailBox>
    );
  }
}

export default DetailLabel;
