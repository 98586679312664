import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { message } from "antd";

import "./index.less";

const ModalComponent = Loader.loadBaseComponent("ModalComponent");
const IconFont = Loader.loadBaseComponent("IconFont");
const NoData = Loader.loadBaseComponent("NoData");
const CaptureCard = Loader.loadBusinessComponent("Card", "CaptureCard");

@withRouter
@Decorator.businessProvider("tab", "menu", "user")
class SnapListModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      snapList: []
    };
  }

  componentDidMount() {
    let { snapData } = this.props;
    let startTime = new Date(moment().subtract(30, "d")).getTime();
    let endTime = new Date() * 1;
    let options = {
      startTime,
      endTime,
      aids: [snapData.sourceAid || snapData.source],
      cids: [snapData.personId]
    };
    if (snapData.snapType === 1) {
      options.limit = 1;
      options.startTime =
        snapData.firstCaptureTime * 1000 ||
        new Date(moment().subtract(90, "d")).getTime();
    }
    if (snapData.snapType === 2) {
      options.limit = 1;
    }
    if (snapData.snapType === 3) {
      options.startTime = new Date(moment().subtract(30, "d")).getTime();
      options.limit = 100;
    }
    Service.person.queryAidDetail(options).then(res => {
      this.setState({
        snapList: res.data.list || []
      });
    });
  }
  onClick = async () => {
    let { snapData, handleDeviceListCancel, user } = this.props;
    handleDeviceListCancel();
    this.setState({
      visible: false
    });
    const placeIdRes = await Service.organization.getPlaceIdsByUserId({
      organizationId: user.userInfo.organizationId
    });
    let placeIds = placeIdRes.data.placeIds.filter(v => !!v);

    if (
      placeIds.includes(
        BaseStore.device.deviceList.find(v => v.cid == snapData.personId).placeId
      )
    ) {
      this.goPage("deviceFile", { id: snapData.personId });
    } else {
      message.warn("无该设备档案权限");
      return;
    }
    this.goPage("deviceFile", {
      id: snapData.personId
    });
  };
  goPage = (moduleName, data) => {
    this.props.tab.goPage({
      moduleName,
      location: this.props.location,
      data,
      isUpdate: false
    });
  };
  //跳转详情
  goSnapshotDetail = (datas, type) => {
    this.props.handleDeviceListCancel();
    let { tab, location } = this.props;
    let { snapList, data } = this.state;
    const searchData = {
      startTime: moment()
        .subtract(3, "d")
        .valueOf(),
      endTime: moment().valueOf(),
      timerTabsActive: 3
    };
    window.LM_DB.add("parameter", {
      id: datas.id.toString(),
      data: datas,
      list: snapList,
      type,
      searchData,
      baseData: data
    }).then(function() {
      tab.closeTabForName("objSnapshotDetail");
      tab.goPage({
        moduleName: "objSnapshotDetail",
        location,
        data: {
          id: datas.id
        }
      });
    });
  };
  render() {
    const { handleDeviceListCancel, visible, snapData, menu } = this.props;
    let { snapList } = this.state;
    let hasMenu = menu.getInfoByName("deviceFile");
    return (
      <ModalComponent
        className="personnel-collegue-modal"
        visible={visible}
        destroyOnClose={true}
        onCancel={() => handleDeviceListCancel && handleDeviceListCancel()}
        otherModalFooter={true}
        width={
          snapList.length >= 4 ? "753px" : `${370 + 80 * snapList.length}px`
        }
        title={snapData.name || ""}
      >
        <div className="collegue-modal">
          <div className="header">
            <div className="address">
              <p className="address-label">
                {`${snapData.sourceAid ? "姓名" : "虚拟身份"}：${
                  snapData.source
                }`}
              </p>
            </div>
            {hasMenu && (
              <div className="header-go" onClick={this.onClick}>
                <IconFont collType={"icon-S_Bar_NameCard"} theme="outlined" />
                进入档案查看更多
              </div>
            )}
          </div>
          <div className="collague-content">
            {snapList.map(v => {
              return (
                <CaptureCard
                  className="detail-box-item"
                  relativeIcon=""
                  onClick={this.goSnapshotDetail.bind(this, v, "face")}
                  hoverScale={false}
                  captureTime={v.captureTime}
                  deviceName={v.deviceName}
                  imgUrl={v.faceUrl}
                />
              );
            })}
          </div>
        </div>
      </ModalComponent>
    );
  }
}

export default SnapListModal;
