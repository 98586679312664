import React from "react";
import moment from "moment";
import {omit} from 'lodash'
import { withRouter } from "react-router-dom";
import {OMPProvider} from './components/ompContext'
import ObjectMapPersonnelDetailPloy from "./components/detail";

@withRouter
@Decorator.withEntryLog()
@Decorator.businessProvider("tab")
class OMPConsumer extends React.Component {
  constructor(props) {
    super(props);
    this.frequentList = []; // 抓拍记录
    this.state = {
      loading: true,
      detailType: "",
      collVisible: false, // 同行活动规律弹窗
      personData: {}, // 个人信息
      collType: 1, // 同行 常去地点modal类型
      currentData: {},
      roomList: [], // 同屋列表
      travelList: [], // 出行规律
      appearance: [], // 体貌特征
      trackCount: [], // 活动规律
      trackCountDays: 0, // 查询天数
      frequentLocation: [], // 常去地点
      passDeviceList: [], // aid经过设备列表
      headerLoading: true,
      tagsLoading: true,
      detailLoading: true,
      roomLoading: true,
      frequstLoading: true
    };
  }

  componentDidMount() {
    SocketEmitter.on(SocketEmitter.eventName.updatePerson, this.updatePerson);
  }

  componentWillUnmount() {
    SocketEmitter.off(SocketEmitter.eventName.updatePerson, this.updatePerson);
  }

  updatePerson = (data = {}) => {
    const { personData } = this.state;
    if (personData.personId === data.id || data.personId) {
      personData.isFocus = data.isFocus;
      this.setState({ personData });
    }
  };
  /**
   * @description 获取人员详细信息
   */
  init = (data, type = "aid") => {
    this.setState({ detailType: type });
    return this.getPersonById(data).then(personData => {
      let {
        tagsUrl,
        focusInfoUrl,
        firstAppearanceUrl,
        recentAppearanceUrl,
        roommatesUrl,
        accompaniesUrl,
        detailAppearanceUrl,
        aidCidRelation,
        frequentedPlacesUrl
      } = personData;
      let { roomList, appearance } = this.state;
      Promise.all([
        Utils.catchPromise(Service.person.getFocusInfos(focusInfoUrl)),
        type !== "entry"
          ? Utils.catchPromise(
              Service.person.queryFirstAppearance(firstAppearanceUrl)
            )
          : this.emptyPromise(), // 首次出现
        type !== "entry"
          ? Utils.catchPromise(
              Service.person.queryRecentAppearance(recentAppearanceUrl)
            )
          : this.emptyPromise(), // 最近出现
        Utils.catchPromise(
          Service.community.queryVehiclesByPersonId({
            id: personData.personId || personData.aid
          })
        ), //查询人员下得车辆
        type !== "entry"
          ? Utils.catchPromise(
              Service.person.queryAidAndCidRelation(aidCidRelation)
            )
          : this.emptyPromise() // 查询aid与设备的关系
      ]).then(resArr => {
        const [
          focusRes,
          firstRes,
          recentRes,
          vehicleRes,
          cidRelationRes
        ] = resArr;
        focusRes &&
          focusRes.data.map(v => {
            if (v.personId === personData.personId) {
              personData.isFocus = v.isFocus;
            } else if (v.aid === personData.aid) {
              personData.isFocus = v.isFocus;
            }
          });
        personData = Object.assign(
          {},
          personData,
          firstRes ? omit(firstRes.data, ["aid"]) : {},
          recentRes ? recentRes.data : {},
          { vehicles: vehicleRes || [] },
          { cidRelation: cidRelationRes ? cidRelationRes.data : {} },
          data
        );
        this.setState({ personData, headerLoading: false });
      });
      Utils.catchPromise(Service.person.queryPersonTags(tagsUrl)).then(res => {
        personData.tags = this.formatLabel(res ? res.data.tags : []);
        this.setState({ personData, tagsLoading: false });
      });
      Promise.all([
        type !== "aid"
          ? Utils.catchPromise(
              Service.person.queryPersonRoommates(roommatesUrl)
            )
          : this.emptyPromise(), // 同屋
        type !== "entry"
          ? Utils.catchPromise(
              Service.person.queryPersonAccompanies(accompaniesUrl, personData)
            )
          : this.emptyPromise() // 同行
      ]).then(resArr => {
        roomList = resArr[0] ? resArr[0].data.slice(0, 5) : [];
        roomList.forEach(v => v.dataType === "0");
        let collList = resArr[1] ? resArr[1].data.list.slice(0, 5) : [];
        collList.forEach(v => v.dataType === "1");
        roomList = roomList.concat(collList);
        this.setState({ roomList, roomLoading: false });
      });
      type !== "entry"
        ? Utils.catchPromise(
            Service.person.getPersonDetailAppearance(detailAppearanceUrl)
          ).then(res => {
            appearance = res ? res.data : [];
            this.setState({ appearance, detailLoading: false });
          })
        : this.emptyPromise(); //同行详情
      Promise.all([
        type !== "entry"
          ? Utils.catchPromise(
              Service.person.getPersonFrequentedPlaces(
                frequentedPlacesUrl,
                personData,
                { statisticPeroidType: 0 }
              )
            )
          : this.emptyPromise(), // 7天常去地
        type !== "entry"
          ? Utils.catchPromise(
              Service.person.getPersonFrequentedPlaces(
                frequentedPlacesUrl,
                personData,
                { statisticPeroidType: 1 }
              )
            )
          : this.emptyPromise() // 30天常去地
      ]).then(res => {
        const [Frequently7Res, Frequently30Res] = res;
        personData = Object.assign(
          {},
          personData,
          { Frequently7: Frequently7Res ? Frequently7Res.data : {} },
          { Frequently30: Frequently30Res ? Frequently30Res.data : {} },
          data
        );
        this.setState({
          frequstLoading: false,
          personData
        });
      });
    });
  };

  //制造一个空的promise
  emptyPromise = () => {
    return new Promise(resolve => resolve(false));
  };

  /**
   * @description 获取人员详情
   */
  getPersonById = option => {
    return Service.person.getPersonById(option).then(res => {
      this.setState({
        personData: res.data
      });
      return res.data;
    });
  };
  //内部方法
  formatLabel(tags) {
    if (!Array.isArray(tags)) {
      return [];
    }
    let labelList = [...new Set(tags)];
    labelList = labelList.filter(v => !!v === true);
    labelList = labelList.map(v => (v = v + ""));
    return labelList;
  }

  /**
   * @description 查询aid轨迹聚合统计接口
   */

  queryTrackCount = async days => {
    let { personData, detailType } = this.state;
    this.frequentList = [];
    let option = {
      aids: personData.aid ? [personData.aid] : personData.bindAids || [],
      days
    };
    let startTime30 = moment(
      moment()
        .subtract(30, "d")
        .format("YYYY-MM-DD 00:00:00")
    ).valueOf();
    let startTime7 = moment(
      moment()
        .subtract(7, "d")
        .format("YYYY-MM-DD 00:00:00")
    ).valueOf();
    let endTime = moment().valueOf();
    let optionAid = {
      startTime: days === 7 ? startTime7 : startTime30,
      endTime,
      aids: personData.aid ? [personData.aid] : personData.bindAids || [],
      limit: 10000,
      offset: 0
    };
    let optionAcc = {
      startTime: days === 7 ? startTime7 : startTime30,
      endTime,
      limit: 10000,
      offset: 0
    };
    await this.queryTrackResult(
      {
        accessSignUrl: detailType !== "aid" ? personData.accessRecordsUrl : "",
        trackSignUrl: personData.trackCountUrl,
        placeSignUrl: personData.frequentedPlacesUrl
      },
      { trackOptions: option, accessOptions: optionAcc }
    ).then(
      res => {
        const { trackList, accessList, frequentLocation } = res;
        this.frequentList = [].concat(this.frequentList, accessList);
        this.setState({
          trackCount: trackList,
          trackCountDays: days,
          frequentLocation
        });
      },
      err =>
        this.setState({
          trackCount: [],
          trackCountDays: days,
          frequentLocation: []
        })
    );
    if (detailType !== "entry") {
      await this.queryPassDeviceCount(days);
      Service.person
        .queryAidDetail(optionAid)
        .then((aidRes = { data: { list: [] } }) => {
          this.frequentList = [].concat(aidRes.data.list);
          this.forceUpdate();
        });
    }
  };

  queryTrackResult(
    { accessSignUrl, trackSignUrl, placeSignUrl },
    { trackOptions, accessOptions }
  ) {
    let { personData, detailType } = this.state;
    return Promise.all([
      Utils.catchPromise(
        //entry
        detailType !== "entry"
          ? Service.statistics.queryTrackCount(trackSignUrl, trackOptions)
          : this.emptyPromise()
      ),
      Utils.catchPromise(
        detailType !== "aid"
          ? Service.person.getPersonAccessRecords(accessSignUrl, accessOptions)
          : this.emptyPromise()
      ),
      Utils.catchPromise(
        detailType !== "entry"
          ? Service.person.getPersonFrequentedPlaces(placeSignUrl, personData, {
              statisticPeroidType: trackOptions.days === 7 ? 0 : 1
            })
          : this.emptyPromise()
      )
    ]).then(resArr => {
      const [
        trackRes = { data: [] },
        accRes = {},
        freRes = { data: { places: [] } }
      ] = resArr;
      let trackList = [];
      let accessList = accRes ? accRes.data.list : [];
      let frequentLocation = freRes.data.places;
      frequentLocation.forEach(v => {
        if (!!v.center) {
          v.position = v.center.split(",");
        }
      });
      trackRes.data.forEach(v => {
        v.type = 0;
        trackList.push({
          name: v.deviceName,
          position: [v.longitude, v.latitude],
          count: v.count,
          cid: v.cid,
          list: Array.isArray(v.trackList)
            ? v.trackList.map(item => {
                return {
                  name: item.deviceName,
                  position: [item.longitude, item.latitude],
                  count: item.count,
                  cid: item.cid
                };
              })
            : []
        });
      });
      accRes.data &&
        accRes.data.list.forEach(v => {
          v.type = 1;
          v.captureTime = v.openTime;
          v.url = v.pictureUrl;
          let index = trackList.findIndex(item => item.deviceId === v.deviceId);
          if (index > -1) {
            trackList[index].count++;
          } else {
            trackList.push({
              name: v.deviceName,
              position: [v.longitude, v.latitude],
              count: 1,
              list: [],
              ...v
            });
          }
        });
      this.frequentList = [].concat(this.frequentList, accessList);
      return { trackList, accessList, frequentLocation };
    });
  }

  queryTrackCountForDay = async days => {
    let { personData, detailType } = this.state;
    this.frequentListForDay = [];

    let startTime30 = moment(
      moment()
        .subtract(30, "d")
        .format("YYYY-MM-DD 00:00:00")
    ).valueOf();
    let startTime7 = moment(
      moment()
        .subtract(7, "d")
        .format("YYYY-MM-DD 00:00:00")
    ).valueOf();
    let endTime = moment().valueOf();
    let optionAid = {
      startTime: days === 7 ? startTime7 : startTime30,
      endTime,
      aids: personData.aid ? [personData.aid] : personData.bindAids || [],
      limit: 10000,
      offset: 0
    };
    let optionAcc = {
      startTime: days === 7 ? startTime7 : startTime30,
      endTime,
      limit: 10000,
      offset: 0
    };
    await this.queryTrack(
      {
        accessSignUrl:
          detailType !== "aid"
            ? personData.accessRecordsUrl
            : this.emptyPromise()
      },
      {
        accessOptions: optionAcc
      }
    ).then(res => {
      const { accessList } = res;
      this.frequentListForDay = [].concat(this.frequentListForDay, accessList);
      return this.frequentListForDay;
    });
    if (detailType !== "entry") {
      await Service.person
        .queryAidDetail(optionAid)
        .then((aidRes = { data: { list: [] } }) => {
          this.frequentListForDay = [].concat(aidRes.data.list);
          this.forceUpdate();
          return this.frequentListForDay;
        });
    }
  };

  queryTrack({ accessSignUrl }, { accessOptions }) {
    let { detailType } = this.state;
    return Utils.catchPromise(
      detailType !== "aid"
        ? Service.person.getPersonAccessRecords(accessSignUrl, accessOptions)
        : this.emptyPromise()
    ).then(accRes => {
      let accessList = accRes ? accRes.data.list : [];

      this.frequentListForDay = [].concat(this.frequentListForDay, accessList);
      return accessList;
    });
  }

  /**
   * @description 查询aid经过设备次数
   */
  queryPassDeviceCount = days => {
    const { personData, trackCount = [], detailType } = this.state;
    const option = {
      personId: personData.personId,
      aid: personData.aid,
      days
    };
    return Service.person
      .queryPassDeviceCount(personData.passDeviceCount, option)
      .then(res => {
        let list = res.data || [];
        trackCount.forEach(v => {
          if (v.openTime) {
            list.push(v);
          }
        });
        this.setState({ passDeviceList: list });
      });
  };

  /**
   * @description 一天出行规律
   */
  queryActivityRuleOneDay = parms => {
    let { personData } = this.state;
    return Service.person
      .queryTravelRuleInOneDay(personData.travelRuleInOneDayUrl, parms)
      .then(res => {
        let arr = [];
        res.data.map((item, index) => {
          arr[index] = item.rules.map(v => v.count);
        });
        [arr[0], arr[1]] = [arr[1], arr[0]];
        this.setState({
          travelList: arr
        });
      });
  };

  //时间格式
  timeSubtract = (days, format) => {
    let { personData } = this.state;
    let time = personData.recentAppearanceTime
      ? personData.recentAppearanceTime * 1
      : moment();
    return moment(
      moment(time)
        .subtract(days, format)
        .format("YYYY-MM-DD 00:00:00")
    ).valueOf();
  };

  //近一月出行规律
  queryTravelRuleByMouth = type => {
    let { personData } = this.state;
    let time = personData.recentAppearanceTime
      ? personData.recentAppearanceTime * 1
      : moment();
    let dayIndex = 0;
    const LAST_MONTH_DAYS = +moment(time)
      .add("year", 0)
      .month(moment().month() - 1)
      .endOf("month")
      .format("DD");
    let option = {
        endTime: moment(
          moment(time)
            .add(1, "d")
            .format("YYYY-MM-DD 00:00:00")
        ).valueOf()
      },
      averageOption = {
        type: 0
      };
    if (type === 7) {
      dayIndex = +moment(time).format("d");
      if (dayIndex !== 0) {
        option.startTime = this.timeSubtract(parseInt(dayIndex) + 6, "d");
      } else {
        option.startTime = this.timeSubtract(13, "d");
      }
    } else {
      averageOption.type = 1;
      dayIndex = moment(time).format("D");
      option.startTime = this.timeSubtract(
        parseInt(dayIndex) + LAST_MONTH_DAYS - 1,
        "d"
      );
    }
    let day = Service.person.queryTravelRuleByDay(
      personData.travelRuleByDayUrl,
      option
    );
    let average = Service.person.queryAverageTravelRule(
      personData.averageTravelRuleUrl,
      averageOption
    );
    Promise.all([day, average]).then(res => {
      let list = [];
      let arr = res[0].data.map(v => v.count);
      if (type === 7) {
        list[1] = arr.splice(0, 7);
        list[0] = arr;
      } else {
        list[1] = arr.splice(0, LAST_MONTH_DAYS);
        list[0] = arr;
      }
      list[2] = res[1].data.map(v => v.count);
      this.setState({
        travelList: list
      });
    });
  };

  /**
   * @description 获取两个人员的详细同行信息
   */
  queryDetailAccompany = parms => {
    let { personData } = this.state;
    let option = {
      aid2: parms.aid
    };
    return Service.person
      .queryDetailAccompany(
        personData.detailAccompanyUrl,
        option,
        personData.aid
      )
      .then(res => {
        let data = res.data || [];
        let accOption = {
          count: data.length,
          list: [],
          title: parms.aid
        };
        data.forEach(v => {
          accOption.list.push(...v.accompanyCaptureInfos);
        });
        return accOption;
      });
  };

  //同行弹窗
  changeCOllModal = async (type, data = {}) => {
    let currentData;
    let { trackCountDays } = this.state;
    let self = this;
    if (type === 2) {
      currentData = await this.queryDetailAccompany(data);
      this.setState({ currentData, collVisible: true, collType: type });
    } else {
      if (!data.placeType) {
        data.placeType = trackCountDays === 7 ? 1 : 2;
      }
      this.queryTrackCountForDay(data.placeType === 1 ? 7 : 30).then(res => {
        let list = self.frequentListForDay.filter(
          v => v.placeId === data.placeId
        );
        currentData = {
          title: data.placeName || "",
          count: data.count || 0,
          days: data.days || 0,
          placeType: data.placeType,
          placeId: data.placeId,
          list: list
        };
        this.setState({ currentData, collVisible: true, collType: type });
      });
    }
  };

  //关闭同行弹窗
  handleCollCancel = () => {
    this.setState({
      collVisible: false
    });
  };

  //setPersonData 提供子组件改变父组件数据
  setPersonData = data => {
    this.setState(
      { personData: data },
      SocketEmitter.emit(SocketEmitter.eventName.updatePerson, data)
    );
  };

  /**
   * @description 设置关注
   */
  onFollow = isFocus => {
    let personData = { ...this.state.personData, isFocus };
    this.setState(
      {
        personData
      },
      SocketEmitter.emit(SocketEmitter.eventName.updatePerson, personData)
    );
  };
  //跳转抓拍记录
  goSnop = () => {
    const { tab, location } = this.props;
    const { personData } = this.state;
    let aids;
    if (personData.personId && personData.hasAid) {
      aids = personData.bindAids.join();
    }
    if (personData.personId && !personData.hasAid) {
      aids = personData.personId;
    }
    if (personData.aid) {
      aids = personData.aid;
    }
    let logInfo = {
      code: 107703,
      parent: 107700,
      text: "查询虚拟人员抓拍记录",
      description: `查看人员【${aids}】的抓拍记录`
    };
    Service.logger.save(logInfo);
    let id = Utils.uuid();
    LM_DB.add("parameter", {
      id,
      data: personData
    }).then(() => {
      tab.goPage({
        moduleName: "objectMapPersonnelSnapshot",
        location,
        data: { id }
      });
    });
  };

  jumpVehicleDetail = vehiclePlateNum => {
    const { tab } = this.props;
    const id = Utils.uuid();
    tab.openDetailPage({
      moduleName: "vehicleFile",
      value: id,
      data: { id },
      beforeAction: async () => {
        await window.LM_DB.add("parameter", {
          id,
          vehiclePlateNum,
          isRigested: true
        });
      }
    });
  };
  queryAccessControl = () => {
    return
  }
  render() {
    return (
      <OMPProvider
        value={{
          ...this.state,
          frequentList: this.frequentList,
          init: this.init,
          onFollow: this.onFollow,
          goSnop: this.goSnop,
          jumpVehicleDetail: this.jumpVehicleDetail,
          queryTrackCount: this.queryTrackCount,
          queryAccessControl: this.queryAccessControl,
          queryActivityRuleOneDay: this.queryActivityRuleOneDay,
          queryTravelRuleByMouth: this.queryTravelRuleByMouth,
          queryDetailAccompany: this.queryDetailAccompany,
          changeCOllModal: this.changeCOllModal,
          handleCollCancel: this.handleCollCancel,
          setPersonData: this.setPersonData
        }}
      >
        <ObjectMapPersonnelDetailPloy />
      </OMPProvider>
    );
  }
}
export default OMPConsumer 
