import React from "react";
import { Radio, message } from "antd";
import { sortBy, isEmpty } from "lodash";
import { OMP } from "../ompContext";
import FrequentPlace from "./components/FrequentPlace";
import PropTypes from "prop-types";

import "./index.less";

const Loading = Loader.Loading;
const RadioGroup = Radio.Group;
const IconFont = Loader.loadBaseComponent("IconFont");
const Button = Loader.loadBaseComponent("LButton", "LButton");
const PersonPathGraph = Loader.loadBusinessComponent("MapComponent", "PersonPathGraph");
/**
 * @param {Function} queryTrackCount 获取抓拍记录
 * @param {Function} changeCOllModal 打开抓拍记录模态框
 * @param {Object} data 人员基本信息
 * @param {Array} trackCount 抓拍记录
 */

@Decorator.businessProvider("tab")
@OMP
class ActivityRule extends React.Component {
  static propTypes = {
    trackCount: PropTypes.array,
    frequentList: PropTypes.array,
    data: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      value: 7,
      fullScreenState: false,
      currentPlace: "",
      trackKey:Math.random()
    };
    this.trajectory = null;
    this.ActivityRuleMap = React.createRef();
  }

  componentDidMount() {
    let { queryTrackCount, personData } = this.props;
    !isEmpty(personData) && queryTrackCount(7);
  }
  componentWillUnmount() {
    this.trajectory = null;
    this.ActivityRuleMap = null;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.trackCount !== this.props.trackCount) {
      this.setState({ trackKey: Math.random() });
    }
  }

  onChange = e => {
    let { queryAccessControl, queryTrackCount, detailType } = this.props;
    this.setState({ value: e.target.value, currentPlace: {} });
    if (detailType === "entry") {
      queryAccessControl(e.target.value);
    } else {
      queryTrackCount(e.target.value);
    }
  };

  //获取带边界的常去地点
  getFrequentLocations = async frequentTop5 => {
    let frequentTop5s = [];
    if (frequentTop5.length) {
      for (let i = 0; i < frequentTop5.length; i++) {
        let item = frequentTop5[i];
        let polyline = [];
        let res = await Service.place.placesExt({ id: item.placeId });
        if (res.data && res.data.polyline) {
          polyline = JSON.stringify(res.data.polyline.split(";").map(v => v.split(",").map(x => x * 1)));
        } else {
          polyline = null;
        }
        frequentTop5s.push({ ...item, polyline });
      }
    }
    return frequentTop5s;
  };

  init = trajectory => {
    const { frequentLocation = [], trackCount } = this.props;
    let trackCounts = trackCount;
    this.trajectory = trajectory;
    let frequentTop5 = sortBy(frequentLocation, "count")
      .reverse()
      .slice(0, 5);
    this.getFrequentLocations(frequentTop5)
      .then(data => this.trajectory.createMapGraph(trackCounts, data))
      .catch(() => this.trajectory.createMapGraph(trackCounts, frequentTop5));
  };

  goTrave = () => {
    const { tab, location, frequentList = [], personData } = this.props;
    if (frequentList.length === 0) {
      return;
    }
    let aids;
    if (personData.personId) {
      aids = personData.personId;
    }
    if (personData.aid) {
      aids = personData.aid;
    }
    let logInfo = {
      description: `播放人员【${aids}】的轨迹`,
      code: 107703,
      parent: 107700,
      text: "查询aid轨迹聚合统计接口"
    };
    Service.logger.save(logInfo);
    const id = Utils.uuid();
    LM_DB.add("parameter", {
      id,
      list: frequentList,
      type: "face"
    }).then(() => {
      tab.goPage({
        moduleName: "resourceTrajectory",
        location,
        data: { id }
      });
    });
  };

  clickPlace = parms => {
    const { changeCOllModal, personData } = this.props;
    let ID;
    if (personData.personId) {
      ID = personData.personId;
    } else {
      ID = personData.aid;
    }
    const logInfo = {
      description: `查看人员【${ID}】的常去地点`,
      code: 107703,
      parent: 107700,
      text: "获取单个人员常去地及在常去地出现的次数"
    };
    Service.logger.save(logInfo);
    changeCOllModal && changeCOllModal(1, parms);
  };

  checkOutScreen = () => {
    let { fullScreenState } = this.state;
    if (fullScreenState) {
      this.setState({ fullScreenState: false }, () => Utils.exitFullscreen());
    } else {
      this.setState({ fullScreenState: true }, () => Utils.fullscreen(this.ActivityRuleMap.current));
    }
  };

  changePlace = currentPlace => {
    if (currentPlace.center) {
      this.trajectory.amap.setZoomAndCenter(17, currentPlace.center.split(","));
    } else {
      message.warn("当前常去地暂无经纬度");
    }
    this.setState({ currentPlace }, () => {
      if (currentPlace.center) {
        this.trajectory.updatePlaceMarkers(currentPlace);
      }
    });
  };

  render() {
    const { currentPlace, value } = this.state;
    const { trackCount = [], passDeviceList = [], frequentList = [], frequentLocation = [], detailType } = this.props;
    const FRE_LEN = frequentList.length;
    return (
      <div className="activity-rule">
        <div className="rule-header">
          <div className="title">活动规律：</div>
          <div className="flutter">
            <RadioGroup onChange={this.onChange} defaultValue={7}>
              <Radio value={7}>近一周</Radio>
              <Radio value={30}>近一月</Radio>
            </RadioGroup>
          </div>
        </div>
        <div className={this.state.fullScreenState ? "rule-content fullScreen" : "rule-content"} ref={this.ActivityRuleMap}>
          <Button className="screen" onClick={() => this.checkOutScreen()}>
            <IconFont type={!this.state.fullScreenState ? "icon-S_View_FullScreen" : "icon-S_View_ExitFullScreen"} />
          </Button>
          {frequentLocation.length > 0 && (
            <FrequentPlace changePlace={this.changePlace} currentPlace={currentPlace} value={value} />
          )}
          <PersonPathGraph
            init={this.init}
            key={this.state.trackKey}
            hasInfo={true}
            clickPlace={this.clickPlace}
            changePlace={this.changePlace}
            currentPlace={currentPlace}
            passDeviceList={passDeviceList}
            resetToolsClassName={frequentLocation.length > 0 ? "person-reset-tool" : "person-reset-tool-no"}
          />
          {trackCount.length > 0 && (
            <div className={`go-trave ${!!FRE_LEN ? "" : "go-wait"}`} onClick={this.goTrave}>
              <IconFont type="icon-S_Bar_Trajectory" theme="outlined" />
              <p className="trave-span">播放轨迹</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ActivityRule;
