(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("moment"), require("antd"), require("ReactRouterDOM"), require("_"));
	else if(typeof define === 'function' && define.amd)
		define("objectMapPersonnelDetail", ["React", "moment", "antd", "ReactRouterDOM", "_"], factory);
	else if(typeof exports === 'object')
		exports["objectMapPersonnelDetail"] = factory(require("React"), require("moment"), require("antd"), require("ReactRouterDOM"), require("_"));
	else
		root["objectMapPersonnelDetail"] = factory(root["React"], root["moment"], root["antd"], root["ReactRouterDOM"], root["_"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__5__) {
return 