/*
 * @Author: suyi
 * @Date: 2019-03-14 13:43:14
 * @Last Modified by: Joey
 * @Last Modified time: 2019-07-17 11:44:24
 */
import React from 'react';
import { OMP } from '../ompContext';
import DetailBox from '../DetailBox';
import './index.less';

const Loading = Loader.Loading;
const PersonRoom = Loader.loadBusinessComponent('Statistics', 'PersonRoom');
const NoData = Loader.loadBaseComponent('NoData');

/**
 * @desc 人员档案详情同屋同行关系
 * @param {Array} list 同行列表
 * @param {Object} data 人员基本信息
 */
@OMP
class DetailPersonnelRoom extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let { personData = {}, changeCOllModal, roomList, frequentLocation,jumpVehicleDetail,showSnapList,roomLoading,headerLoading,frequstLoading } = this.props;
    return (
      <DetailBox title="关系图谱：" className="detail-personnel-room">
        {!roomLoading && !headerLoading && !frequstLoading ? ((!!roomList.length || Object.keys(personData.cidRelation).length || (personData.vehicles && personData.vehicles.length) || personData.firstRes || personData.recentRes) ? (
          <PersonRoom jumpVehicleDetail={jumpVehicleDetail} changeCOllModal={changeCOllModal} data={personData} list={roomList} showSnapList={showSnapList} />
        ) : (
          <NoData imgType={4} key="dps" title="数据 , 系统正在监控分析中···" />
        )) : <Loading />}
      </DetailBox>
    );
  }
}

export default DetailPersonnelRoom;
