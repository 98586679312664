import React from 'react';
import DetailBox from '../DetailBox';
import {OMP} from '../ompContext'
import './index.less';

const Loading = Loader.Loading;
const IconFont = Loader.loadBusinessComponent('IconFont');
const Tags = Loader.loadBusinessComponent('Card', 'Tags');
const NoData = Loader.loadBaseComponent("NoData");

/**
 * @desc 体貌特征
 * @param {Array} appearance 体貌特征
 */
@OMP
class DetailFeatures extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      dictLabel: [],
      simpleData: [],
      detailedData: []
    };
  }

  componentDidMount() {
    this.format(this.props.appearance);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.appearance !== this.props.appearance) {
      this.format(nextProps.appearance);
    }
  }

  getIcon = code => {
    const list = [
      { value: '112100', icon: 'icon-L_Person_ClothesStripe' },
      { value: '112800', icon: 'icon-S_AID_Glasses' },
      { value: '119010', icon: 'icon-S_AID_SunGlasses' },
      { value: '112400', icon: 'icon-L_Person_ClothesSolid' },
      { value: '112500', icon: 'icon-L_Person_PantsTrousers' },
      { value: '112200', icon: 'icon-L_Person_PantsShorts' },
      { value: '119000', icon: 'icon-L_Person_Hat' },
      { value: '100250', icon: 'icon-L_Person_MouseMask' },
      { value: '112300', icon: 'icon-L_Person_Backpack' }
    ];
    return list.find(v => v.value === code).icon || 'icon-S_Bar_PeopleAll';
  };
  getSort = data => {
    let item = {...data}
    switch(data.tagTypeCode){
      case '119000' :
      item.sort = 1
      break;
      case '112800' :
      item.sort = 2
      break;
      case '119010' :
      item.sort = 3
      break;
      case '100250' :
      item.sort = 4
      break;
      case '112300' :
      item.sort = 5
      break;
      case '112400' :
      item.sort = 6
      break;
      case '112100' :
      item.sort = 7
      break;
      case '112500' :
      item.sort = 8
      break;
      case '112200' :
      item.sort = 9
      break;
      default:
      item
    }
    return item
  }

  format = data => {
    data.forEach(v => v.count = v.list.map(item => parseInt(item.count)).reduce((a, b) => a + b));
    let tagTypeCodeArr = data.map(v => v.tagTypeCode);
    let dictLabel = Dict.typeCode.filter(v => tagTypeCodeArr.includes(v.code));
    let arr = [];
    dictLabel.map(v => {
      arr = arr.concat(Dict.getDict(v.name));
    });
    let simpleData = [],
      detailedData = [];
    let datas = data.map(v => this.getSort(v)).sort((a,b) => a.sort - b.sort)
    detailedData = datas.map((v, index) => {
      if (v.list.length > 4) {
        let arr = v.list;
        let arrNew = arr.splice(4);
        v.list = arr;
        arr[3].tagCode = 0;
        arrNew.forEach(v => (arr[3].count += v.count));
      }
      return {
        title: dictLabel.find(item => item.code === v.tagTypeCode).label,
        tags: v.list.map(item => {
          return {
            name: arr.find(v2 => v2.value == item.tagCode)
              ? arr.find(v2 => v2.value == item.tagCode).label
              : '其他',
            value: item.count
          };
        }),
        icon: this.getIcon(v.tagTypeCode)
      };
    });
    simpleData = detailedData.slice(0, 6);
    this.setState({
      simpleData,
      detailedData
    });
  };
  more = visible => {
    this.setState({
      visible
    });
  };

  render() {
    let { visible, simpleData, detailedData } = this.state;
    const {detailLoading} = this.props
    const dataFirst = detailedData.slice(0, 4);
    const dataSecend = detailedData.slice(4, 10);
    return (
      <DetailBox className="detail-features" title="体貌特征：">
        <div className={`features-content ${visible ? 'features-more' : ''}`}>
          {!!simpleData.length ? <div className="content">
          <div className='near-time'>近一月出行</div>
            <div className="content-label">
              {simpleData.map(v => (
                <Tags
                  key={v.title}
                  singleTag={true}
                  data={v.tags}
                  icon={v.icon}
                />
              ))}
            </div>
            <div className="label-footer" onClick={() => this.more(true)}>
              <IconFont type={'icon-S_Arrow_BigDown'} theme="outlined" />
              看更多
            </div>
            <div className="detail-info">
              <div className="info-top">
                {dataFirst.map(v => (
                  <Tags
                    key={v.title}
                    title={v.title}
                    data={v.tags}
                    icon={v.icon}
                  />
                ))}
              </div>
              <div className="info-top">
                {dataSecend.map(v => (
                  <Tags
                    key={v.title}
                    title={v.title}
                    data={v.tags}
                    icon={v.icon}
                  />
                ))}
              </div>
            </div>
            <div className="label-footer" onClick={() => this.more(false)}>
              <IconFont type={'icon-S_Arrow_BigUp'} theme="outlined" />
              收起
            </div>
          </div> : detailLoading ? <Loading /> : <NoData key='df' pdt={1} />}
        </div>
      </DetailBox>
    );
  }
}

export default DetailFeatures;
