/*
 * @Author: suyi
 * @Date: 2019-03-01 14:08:15
 * @Last Modified by: Joey
 * @Last Modified time: 2019-07-25 14:17:39
 */
import React, { Component } from "react";
import { Popover } from "antd";
import { OMP } from "../ompContext";
import DetailBox from "../DetailBox";
import "./index.less";

const Loading = Loader.Loading;
const IconFont = Loader.loadBaseComponent("IconFont");
const ImageView = Loader.loadBusinessComponent("ImageView");
const LabelValue = Loader.loadBaseComponent("LabelValue");

/**
 * @desc 人员档案详情header
 * @param {Object}  data 人员数据
 * @param {Boolean} hasPersonId 是否有PersonId
 * @param {Booleam} hasAid 是否有Aid
 * @param {Function} onFollow 关注方法
 * @param {Function} onRelation 关联方法
 */

@OMP
class DetailHeader extends Component {
  constructor(props) {
    super(props);
    this.infoRef = React.createRef();
  }
  state = {
    pictureList: [],
    imgUrl: {}
  };

  componentDidMount() {
   this.initImgs()
  }

  componentWillReceiveProps(nextProps){
    if(this.props.personData !== nextProps.personData){
      this.initImgs()
    }
  }
  
  initImgs = () => {
    const { personData } = this.props;
    let pictureList = [];
    personData.portraitPictureUrl &&
      pictureList.push({
        url: personData.portraitPictureUrl,
        type: "代表照片"
      });
    if (Array.isArray(personData.aidPictureInfos)) {
      personData.aidPictureInfos.forEach(v => {
        v.newestPictureUrl &&
          pictureList.push({ url: v.newestPictureUrl, type: "代表照片" });
        v.lastestPictureUrl &&
          pictureList.push({ url: v.lastestPictureUrl, type: "代表照片" });
      });
    }
    personData.recentUrl &&
      pictureList.push({ url: personData.recentUrl, type: "最近出现" });
    personData.firstUrl &&
      pictureList.push({ url: personData.firstUrl, type: "首次出现" });
    let imgUrl = pictureList[0]
      ? { url: pictureList[0].url, type: pictureList[0].type }
      : {};
    this.setState({
      pictureList,
      imgUrl
    });
  }

  handleImg = item => {
    this.setState({
      imgUrl: item
    });
  };

  renderItem = item => {
    return (
      <div className="wheel-box">
        <ImageView className="wheel-img" src={item.url} />
      </div>
    );
  };

  /**
   * @description 设置关注
   */
  onFollow = event => {
    event.stopPropagation();
    let { personData, onFollow } = this.props;
    let option = {
      personId: personData.personId,
      isFocus: !personData.isFocus
    };
    if (personData.aid) {
      option.personId = undefined;
      option.aid = personData.aid;
    }
    Service.person.setOrCancelFocus(option).then(() => {
      onFollow && onFollow(!personData.isFocus);
    });
  };
  getTitle = value => {
    const { hasAid, personData } = this.props;
    const {
      personName,
      ageSection,
      identityCardNumber,
      age,
      bindAids = [],
      mobile,
      educationDegree,
      gender,
      address,
      maritalStatus,
      nationality,
      aid
    } = personData;
    switch (value) {
      case "姓名":
        return personName;
      case "年龄段":
        return ageSection && Dict.getLabel("generation", ageSection);
      case "证件号码":
        return identityCardNumber;
      case "年龄":
        return age && `${age}岁`;
      case "虚拟身份":
        return hasAid || aid ? aid : bindAids[0];
      case "文化程度":
        return (
          educationDegree && Dict.getLabel("degreeEducation", educationDegree)
        );
      case "民族":
        return nationality && Dict.getLabel("nation", nationality);
      case "手机号":
        return mobile;
      case "性别":
        return gender && Dict.getLabel("sex", gender);
      case "地址":
        return address;
      case "婚姻状态":
        return maritalStatus && Dict.getLabel("marital", maritalStatus);
      default:
        return "暂无";
    }
  };
  //baseInfo
  getInfo = name => {
    let val = this.getTitle(name);
    return (
      <LabelValue
        label={name}
        className="info"
        value={val || "暂无"}
        fontSize={14}
      />
      // <div className="info">
      //   <p className="info-name">{name}：</p>
      //   <span className="info-val">{val || "暂无"}</span>
      // </div>
    );
  };
  //aidInfo
  getAidInfo = (name, val, time) => {
    return (
      <div className="info">
        <p className="info-name">{name}：</p>
        <div className="info-time">
          <div className="address">
            <IconFont type={"icon-S_Bar_Add"} theme="outlined" />
            <span className="info-val" title={val}>
              {val || "暂无"}
            </span>
          </div>
          <div className="address">
            <IconFont type={"icon-M_Bar_Clock"} theme="outlined" />
            <span className="info-val">
              {time ? Utils.formatTimeStamp(time) : "暂无"}
            </span>
          </div>
        </div>
      </div>
    );
  };

  getVehicleName = (value, data) => {
    let { vehiclePlateNo, vehicleBrand, vehicleType, vehicleColor } = data;
    switch (value) {
      case "车牌号码":
        return vehiclePlateNo;
      case "车辆品牌":
        return vehicleBrand && Dict.getLabel("vehicleBrands", vehicleBrand);
      case "车辆类型":
        return vehicleType && Dict.getLabel("vehicleType", vehicleType);
      case "车辆颜色":
        return (
          vehicleColor && Dict.getLabel("vehicleColor", vehicleColor, true)
        );
      default:
        return "暂无";
    }
  };

  getVehicleInfo = vehicle => {
    const names = ["车牌号码", "车辆品牌", "车辆类型", "车辆颜色"];
    return (
      <div className='vehicle-infos'>
        <div className='info'>{names.slice(0,2).map(name => (
          <LabelValue
            label={name}
            value={this.getVehicleName(name, vehicle) || '暂无'}
            fontSize={14}
          />
        ))}</div>
         <div className='info'>{names.slice(2,4).map(name => (
          <LabelValue
            label={name}
            value={this.getVehicleName(name, vehicle) || '暂无'}
            fontSize={14}
          />
        ))}</div>
      </div>
    );
  };

  render() {
    let {
      onRelation,
      personData,
      goSnop,
      hasPersonId = false,
      hasAid = false,
      headerLoading
    } = this.props;
    const {
      isFocus,
      personName,
      recentAppearanceAddress,
      recentAppearanceTime,
      firstAppearanceAddress,
      firstAppearanceTime,
      vehicles
    } = personData;
    const { pictureList, imgUrl } = this.state;
    return (
      <DetailBox title="基本信息：" className="objectMap-ploy-detail-header">
        <div className="detail-content">
         <div className="content-left">
            <div className="picture-box">
              <ImageView className="image" src={imgUrl.url} key={imgUrl.type} />
              <p className="picture-info">{imgUrl.type}</p>
            </div>
            {pictureList.slice(0, 3).map(v => (
              <ImageView
                className={
                  v.url === imgUrl.url ? "mini-image active" : "mini-image"
                }
                onClick={() => this.handleImg(v)}
                src={v.url}
                key={v.type}
              />
            ))}
            <span
              className="mini-more"
              title="查看更多图集"
              onClick={() => goSnop()}
            >
              <IconFont type="icon-S_View_Image" />
            </span>
          </div>
          <div className="content-right">
            <div className="info-header" ref={this.infoRef}>
              <span>
                <span>姓名：</span>
              </span>
              <strong>{personName || "暂无"}</strong>
              <Popover
                placement="left"
                content={isFocus ? "取消关注" : "关注"}
                getPopupContainer={() => this.infoRef.current}
              >
                <IconFont
                  type={
                    isFocus ? "icon-S_Photo_FollowYes" : "icon-S_Photo_FollowNo"
                  }
                  className={isFocus ? "icon-alive" : ""}
                  theme="outlined"
                  onClick={this.onFollow}
                />
              </Popover>
              {hasPersonId && (
                <Popover
                  placement="left"
                  content={"关联"}
                  getPopupContainer={() => this.infoRef.current}
                >
                  <IconFont
                    type={"icon-S_Bar_Linked"}
                    theme="outlined"
                    className="realtion-icon"
                    onClick={onRelation}
                  />
                </Popover>
              )}
            </div>
            <div className="info-center">
              {/* {[
                  "姓名",
                  "年龄段",
                  "证件号码",
                  "年龄",
                  "虚拟身份",
                  "文化程度",
                  "民族",
                  "手机号",
                  "性别",
                  "地址",
                  "婚姻状态"
                ].map(v => this.getInfo(v))} */}
              {[
                "虚拟身份",
                "证件号码",
                "民族",
                "性别",
                "年龄段",
                "年龄",
                "婚姻状态",
                "文化程度",
                "手机号",
                "地址"
              ].map(v => this.getInfo(v))}
            </div>
            {vehicles && vehicles.length > 0 && (
              <div className="info-center">
                {vehicles.map(vehicle => this.getVehicleInfo(vehicle))}
              </div>
            )}
            <div className="info-center">
              {this.getAidInfo(
                "最近出现",
                recentAppearanceAddress,
                recentAppearanceTime
              )}
              {this.getAidInfo(
                "首次出现",
                firstAppearanceAddress,
                firstAppearanceTime
              )}
            </div>
          </div>
        </div>
      </DetailBox>
    );
  }
}

export default DetailHeader;
