import React from "react";
import { Radio } from "antd";
import moment from "moment";
import { OMP } from "../ompContext";
import DetailBox from "../DetailBox";
import "./index.less";

const RadioGroup = Radio.Group;
const EchartsReact = Loader.loadBaseComponent("EchartsReact");
const NoData = Loader.loadBaseComponent("NoData");
const Loading = Loader.Loading;

@OMP
class TravelRule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 1,
      option: {}
    };
  }
  componentDidMount() {
    this.getTraveRule(1);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.travelList !== this.props.travelList) {
      this.getOtionTem(nextProps.travelList);
    }
  }
  getOtionTem(parms) {
    const { type } = this.state;
    let typeList = [
      {
        name: "当前周",
        textStyle: { color: "#FFAA00" }
      },
      {
        name: "上一周",
        textStyle: { color: "#25DC9B" }
      },
      {
        name: "平均(往前3月内平均统计值)",
        textStyle: { color: "#B5BBC7" }
      }
    ];
    let series = [
      {
        name: "当前周",
        type: "bar",
        smooth: true,
        lineStyle: {
          color: "#ffaa00"
        },
        barWidth: type === 3 ? 8 : 16,
        barGap: 1,
        itemStyle: {
          normal: {
            color: "#ffaa00"
          }
        },
        data: []
      },
      {
        name: "上一周",
        type: "bar",
        smooth: true,
        lineStyle: {
          color: "#25dc9b"
        },
        barWidth: type === 3 ? 8 : 16,
        barGap: 0,
        itemStyle: {
          normal: {
            color: "#25dc9b"
          }
        },
        data: []
      },
      {
        name: "平均(往前3月内平均统计值)",
        type: "line",
        step: "middle",
        // smooth: true,
        symbol: "circle",
        symbolSize: 2,
        sampling: "average",
        lineStyle: {
          color: "#b5bbc7"
        },
        areaStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "#b5bbc7"
              },
              // {
              //   offset: 0.2,
              //   color: "#eee"
              // },
              {
                offset: 1,
                color: "rgba(181,187,199,0.2)"
              }
            ])
          }
        },
        itemStyle: {
          normal: {
            color: "#b5bbc7",
            borderWidth: 1
          }
        },
        data: []
      }
    ];
    let xAxisData = [
      "星期一",
      "星期二",
      "星期三",
      "星期四",
      "星期五",
      "星期六",
      "星期日"
    ];
    if (type === 1) {
      typeList[0].name = "当前日期";
      series[0].name = "当前日期";
      typeList[1].name = "前一天";
      series[1].name = "前一天";
      xAxisData = [
        "凌晨\n01:00~05:59",
        "早晨\n06:00~07:59",
        "上午\n08:00~11:59",
        "中午\n12:00~12:59",
        "下午\n13:00~17:59",
        "傍晚\n18:00~18:59",
        "晚上\n19:00~20:59",
        "深夜\n21:00~00:59"
      ];
    }
    if (type === 30) {
      typeList[0].name = "当月";
      series[0].name = "当月";
      typeList[1].name = "前一月";
      series[1].name = "前一月";
      let arr = [];
      for (let i = 0; i < 31; i++) {
        arr[i] = i + 1;
      }
      xAxisData = arr;
    }
    parms.map((item, index) => {
      series[index].data = item;
    });
    let option = {
      color: ["#FFAA00", "#25DC9B", "#B5BBC7"],
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
          shadowStyle: {
            color: "rgba(255,136,0,0.20)"
          }
        },
        confine: true
      },
      dataZoom: [
        {
          type: "inside",
          throttle: 50
        }
      ],
      legend: {
        icon: "rect",
        // y: 'bottom',
        itemGap: 48,
        itemWidth: 26,
        itemHeight: 2,
        bottom: 15,
        data: typeList
      },
      grid: {
        left: 60,
        right: 60,
        bottom: 70,
        top: 50
      },
      xAxis: {
        type: "category",
        splitNumber: 24,
        boundaryGap: ["20%", "20%"],
        axisTick: true,
        splitLine: {
          show: true,
          lineStyle: {
            color: "#fff",
            width: 2
          }
        },
        axisLabel: {
          color: "#666",
          fontSize: 12,
          fontFamily: "Microsoft YaHei"
        },
        axisLine: {
          show: false
        },
        // data: ['星期\n一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日',]
        data: xAxisData
      },
      yAxis: {
        type: "value",
        axisTick: false,
        minInterval: 1, //最小间隔
        name: "次",
        axisLine: {
          show: false
        },
        axisLabel: {
          color: "#666",
          fontSize: 12,
          fontFamily: "Microsoft YaHei"
        },
        splitLine: {
          show: false
        },
        splitArea: {
          show: true,
          areaStyle: {
            color: ["#F3F6F9", "#fff"]
          }
        }
      },
      series: series
    };
    this.setState({
      option
    });
  }
  getTraveRule = type => {
    const {
      queryTravelRuleByMouth,
      queryActivityRuleOneDay,
      personData
    } = this.props;
    let time = personData.recentAppearanceTime
      ? personData.recentAppearanceTime * 1
      : moment();
    if (type === 1) {
      let option = {
        startTime: moment(
          moment(time)
            .subtract(1, "d")
            .format("YYYY-MM-DD 00:00:00")
        ).valueOf(),
        endTime: moment(
          moment(time)
            .add(1, "d")
            .format("YYYY-MM-DD 00:00:00")
        ).valueOf()
      };
      queryActivityRuleOneDay(option);
    }
    if (type === 7) {
      queryTravelRuleByMouth(7);
    }
    if (type === 30) {
      queryTravelRuleByMouth(30);
    }
    this.setState({ type });
  };

  onChange = e => {
    this.getTraveRule(e.target.value);
  };

  render() {
    const { option } = this.state;
    return (
      <DetailBox
        className="trave-rule"
        title="出行规律："
        renderItem={
          <RadioGroup defaultValue={1} onChange={this.onChange}>
            <Radio value={1}>天</Radio>
            <Radio value={7}>周</Radio>
            <Radio value={30}>月</Radio>
          </RadioGroup>
        }
      >
        {Object.keys(option).length > 0 ? (
          <>
            <div className="near-time">
              最近出现时间：
              {Utils.formatTimeStamp(
                this.props.personData.recentAppearanceTime
              ) || "暂无"}
            </div>
            <EchartsReact
              option={option}
              style={{ width: "100%", height: "100%" }}
            />
          </>
        ) : this.props.detailLoading ? (
          <Loading />
        ) : (
          <NoData />
        )}
      </DetailBox>
    );
  }
}

export default TravelRule;
