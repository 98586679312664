import React from "react";
import { Select } from "antd";
import { OMP } from "../ompContext";
import { cloneDeep } from "lodash";
import "./index.less";

const Option = Select.Option;
const IconFont = Loader.loadBaseComponent("IconFont");
const ModalComponent = Loader.loadBaseComponent("ModalComponent");
const common = [
  [...Dict.map.gait],
  [...Dict.map.height],
  [...Dict.map.fatAndThin]
];
@OMP
class LabelModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tagCodes: JSON.parse(JSON.stringify(this.props.labelList)),
      choseCodes: []
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.labelList !== this.props.labelList) {
      let labelList = JSON.parse(JSON.stringify(nextProps.labelList));
      this.setState({
        tagCodes: labelList
      });
    }
  }
  changeLabel = item => {
    let { tagCodes } = this.state;
    const index = tagCodes.findIndex(v => v === item.value);
    if (index === -1) {
      tagCodes.push(item.value);
    } else {
      tagCodes.splice(index, 1);
    }
    this.setState({
      tagCodes
    });
  };

  //判断
  juge = (arr, value) => {
    return !!arr.filter(v => v == value)[0];
  };
  selectLabel = (list,parms) => {
    let { tagCodes } = this.state;
    let tagCodess = cloneDeep(tagCodes);
    let value = parms.toString();
    if (tagCodess.includes(value)) {
      tagCodess = tagCodess.filter(v => v !== value)
    } else {
      let other = list.filter(x => x.value !== value)[0].value
      if(tagCodess.includes(other)){
        tagCodess = tagCodess.filter(v => v !== other)
      }
      tagCodess.push(value);
    }
    this.setState({
      tagCodes:tagCodess
    });
  };

  onOk = () => {
    let { tagCodes } = this.state;
    tagCodes = tagCodes.filter(
      v => v !== "120120" && v !== "120130" && v !== "120110"
    );
    if (this.props.onOk) {
      this.handleLabelOK(tagCodes);
    }
  };

  handleLabelOK = tagCodes => {
    const { personData, onOk } = this.props;
    const ARR_CODES = [
      "118701",
      "118202",
      "118703",
      "120701",
      "120702",
      "120703",
      "120704",
      "120705",
      "120706",
      "120707",
      "120708",
      "120709",
      "120710",
      "120711",
      "120712",
      "120713",
      "120714",
      "120715",
      "120716",
      "120717",
      "120718",
      "120719",
      "120720"
    ];
    let arr = tagCodes.filter(v => !ARR_CODES.includes(v));
    let option = {
      personId: personData.personId,
      addTagCodes: [],
      deleteTagCodes: []
    };
    if (personData.aid) {
      option.personId = undefined;
      option.aid = personData.aid;
    }
    arr.forEach(v => {
      if (!personData.tags.includes(v)) {
        option.addTagCodes.push(v);
      }
    });
    personData.tags.forEach(v => {
      if (!tagCodes.includes(v)) {
        option.deleteTagCodes.push(v);
      }
    });
    Service.person.addTags(option).then(() => {
      personData.tags = tagCodes;
      onOk(personData);
    });
  };

  getContentSK = (attr, item) => {
    return (
      <div
        className="label label-palce label-chose"
        title="系统正在监控中，暂不支持编辑"
      >
        <div className="label-palce-icon">
          <IconFont type={"icon-S_Bar_Eye"} theme="outlined" />{" "}
        </div>
        {Dict.getLabel(attr, item)}
      </div>
    );
  };

  getContent = item => {
    let { tagCodes } = this.state;
    return (
      <div
        className={`label ${
          tagCodes.find(v => v == item.value) ? "label-chose" : ""
        }`}
        onClick={() => this.changeLabel(item)}
      >
        {item.label}
      </div>
    );
  };
  getSelect = option => {
    let { tagCodes } = this.state;
    let { placeholder, list } = option;
    return (
      <Select
        placeholder={placeholder}
        className={`${
          tagCodes.find(item =>
            this.juge(list.slice(0, 2).map(v => v.value), item)
          )
            ? "select-active"
            : ""
        }`}
        value={
          tagCodes.find(item => this.juge(list.map(v => v.value), item)) ||
          undefined
        }
        style={{ width: 90 }}
        dropdownClassName="label-select-drop"
        onSelect={this.selectLabel.bind(this,list)}
      >
        {list.map(v => {
          return <Option value={v.value}>{v.label}</Option>;
        })}
      </Select>
    );
  };

  render() {
    let { tagCodes } = this.state;
    let { visible, onCancel } = this.props;
    return (
      <ModalComponent
        className="personnel-label-modal"
        visible={visible}
        onOk={this.onOk}
        onCancel={() => onCancel && onCancel()}
        width="648px"
        title="编辑人员标签"
      >
        <div className="label-content">
          <div className="type">
            <div className="name">属性：</div>
            <div className="value">
              {Dict.map.personnelAttr.map(v => this.getContent(v))}
            </div>
          </div>
          <div className="type">
            <div className="name">外观：</div>
            <div className="value">
              {this.getSelect({
                placeholder: "步态",
                list: common[0]
              })}
              {this.getSelect({
                placeholder: "高矮",
                list: common[1]
              })}
              {this.getSelect({
                placeholder: "胖瘦",
                list: common[2]
              })}
            </div>
          </div>
          <div className="type">
            <div className="name">身份：</div>
            <div className="value">
              {Dict.map.identity.map(v => this.getContent(v))}
            </div>
          </div>
          <div className="type">
            <div className="name">行为：</div>
            <div className="value">
              {Dict.map.aidBehavior.map(v => this.getContent(v))}
            </div>
          </div>
          <div className="type">
            <div className="name">时空：</div>
            <div className="value value-palce">
              {tagCodes
                .filter(v => v === "119051")
                .map(v => this.getContentSK("aidBehaviorCode", v))}
              {tagCodes
                .filter(v => v === "118703" || v === "118702")
                .map(v => this.getContentSK("behaviorAttr", v))}
              {tagCodes
                .filter(v => v - 120700 > 0 && v - 120700 < 21)
                .map(v => this.getContentSK("appearPlace", v))}
            </div>
          </div>
        </div>
      </ModalComponent>
    );
  }
}

export default LabelModal;
