import React from "react";
import { withRouter } from "react-router-dom";
import { OMP } from "./ompContext";
import DetailFeatures from "./DetailFeatures";
import DetailHeader from "./DetailHeader";
import DetailLabel from "./DetailLabel";
import DetailPersonnelRoom from "./DetailPersonnelRoom";
import LabelModal from "./LabelModal";
import ActivityRule from "./ActivityRule";
import ColleagueModal from "./ColleagueModal";
import SnapListModal from "./SnapListModal";
import TravelRule from "./TravelRule";
import "../index.less";

const Loading = Loader.Loading;
const NoData = Loader.loadBaseComponent("NoData");
const Wrapper = Loader.loadBaseComponent("TwoColumnLayout");
const SimilarModal = Loader.loadBusinessComponent(
  "ObjectMapPersonnelComponent",
  "SimilarModal"
);

@withRouter
@OMP
class ObjectMapPersonnelDetailPloy extends React.Component {
  constructor(props) {
    super(props);
    this.frequentList = []; // 抓拍记录
    this.state = {
      loadError: false,
      labelVisible: false, // 标签弹窗
      similarVisible: false, //关联aid弹窗
      snapVisible: false, //抓拍图片弹窗
      snapData: {}
    };
    this.detailRef = React.createRef();
  }

  componentDidMount() {
    const { history, init } = this.props;
    const { id } = Utils.queryFormat(history.location.search);
    LM_DB.get("parameter", id).then(data => {
      const { id, type, url } = data;
      init({ [type === "aid" || !type ? "aid" : "personId"]: id, url }, type); //注释
    });
    SocketEmitter.on(
      SocketEmitter.eventName.reverseUpdatePerson,
      this.updatePerson
    );
  }

  componentWillUnmount() {
    SocketEmitter.off(
      SocketEmitter.eventName.reverseUpdatePerson,
      this.updatePerson
    );
  }
  /**
   * @description 处理后更新人员信息
   */
  updatePerson = data => {
    this.props.setPersonData({
      ...this.props.personData,
      tags: data.tagCodes,
      isFocus: data.isFocus
    });
  };

  /** 修改人员标签 */
  handleLabelOK = personData => {
    this.props.setPersonData(personData);
    this.setState({
      labelVisible: false
    });
  };

  changeLabelModal = () => {
    this.setState({
      labelVisible: true
    });
  };

  /**
   * @description 设置关联
   */
  onRelation = event => {
    event.stopPropagation();
    this.setState({
      similarVisible: true
    });
  };

  handleLabelCancel = () => {
    this.setState({
      labelVisible: false
    });
  };
  // 关联aid
  handleSimilarOK = () => {
    this.setState({
      similarVisible: false
    });
  };

  // 关闭关联aid模态框
  handleSimilarCancel = () => {
    this.setState({ similarVisible: false });
  };

  showSnapList = e => {
    this.setState({
      snapVisible: true,
      snapData: e.data
    });
  };
  handleDeviceListCancel = () => {
    this.setState({
      snapVisible: false
    });
  };
  render() {
    let { personData, collVisible,frequstLoading,headerLoading } = this.props;
    const {
      labelVisible,
      similarVisible,
      loadError,
      snapVisible,
      snapData
    } = this.state;
    if (loadError) {
      return <NoData />;
    }
    return (
      <Wrapper
        title={
          personData.personName ||
          personData.aid ||
          (personData.bindAids && personData.bindAids[0])
        }
        className="object-map-personnel-detail-ploy"
      >
        <div className="detail-ploy-content">
          <div className="ploy-scroll" ref={this.detailRef}>
            <DetailHeader
              hasPersonId={!!personData.personId}
              hasAid={!!personData.hasAid || !!personData.aid}
              onRelation={this.onRelation}
            />
            <DetailLabel
              labelList={personData.tags || []}
              onClick={this.changeLabelModal}
            />
            {((personData.hasAid && personData.personId) || personData.aid) && (
              <DetailFeatures />
            )}
            {!frequstLoading && <ActivityRule />}
            {!headerLoading && ((personData.hasAid && personData.personId) || personData.aid) && (
              <TravelRule />
            )}
            <DetailPersonnelRoom showSnapList={this.showSnapList} />
          </div>
        </div>
        <LabelModal
          labelList={personData.tags || []}
          visible={labelVisible}
          onOk={this.handleLabelOK}
          onCancel={this.handleLabelCancel}
        />
        <SimilarModal
          visible={similarVisible}
          onOk={this.handleSimilarOK}
          onCancel={this.handleSimilarCancel}
          personData={personData}
        />
        {collVisible && <ColleagueModal detailRef={this.detailRef} />}
        {snapVisible && (
          <SnapListModal
            visible={snapVisible}
            snapData={snapData}
            handleDeviceListCancel={this.handleDeviceListCancel}
          />
        )}
      </Wrapper>
    );
  }
}

export default ObjectMapPersonnelDetailPloy;
